/* Command provided by Tailwind CLI during build step via PostCSS */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Note, 99% of styles should go in an individual component using Styled Components
   This is only for global styles, of which there should be few and far between. */

.splash-background {
    background: linear-gradient(
            134deg,
            rgba(1, 1, 1, 0.02) 0%,
            rgba(1, 1, 1, 0.02) 16%,
            transparent 16%,
            transparent 76%,
            rgba(58, 58, 58, 0.02) 76%,
            rgba(58, 58, 58, 0.02) 100%
        ),
        linear-gradient(
            215deg,
            rgba(166, 166, 166, 0.02) 0%,
            rgba(166, 166, 166, 0.02) 33%,
            transparent 33%,
            transparent 79%,
            rgba(111, 111, 111, 0.02) 79%,
            rgba(111, 111, 111, 0.02) 100%
        ),
        linear-gradient(
            303deg,
            rgba(215, 215, 215, 0.02) 0%,
            rgba(215, 215, 215, 0.02) 7%,
            transparent 7%,
            transparent 90%,
            rgba(192, 192, 192, 0.02) 90%,
            rgba(192, 192, 192, 0.02) 100%
        ),
        linear-gradient(
            302deg,
            rgba(113, 113, 113, 0.02) 0%,
            rgba(113, 113, 113, 0.02) 34%,
            transparent 34%,
            transparent 73%,
            rgba(65, 65, 65, 0.02) 73%,
            rgba(65, 65, 65, 0.02) 100%
        ),
        linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255));
}
/*Gets rid of the annoying outline for mouse users but preserves it for keyboard users, and is ignored by browsers that don’t support :focus-visible.*/
button:focus:not(:focus-visible),
input:focus:not(:focus-visible) {
    outline: none;
}
